<template>
  <div class="data-box">
      <router-view />
  </div>
</template>
<script>
	// import Top from '../../';

  function enableBeforeUnload() {
      window.addEventListener('beforeunload', function (event) {
        // 在这里可以执行一些操作，例如显示提示信息
        event.returnValue = '确定离开此页面吗？';
		console.log(78978974444);
      });
    }
export default {

}
</script>
<style src="./components/common.css" />
<style lang="scss">
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.data-box{
  height: 100vh;
}
ul,ol{
    list-style: none;
}
.bluez{
	color:#343147;
}
.jianjei{
	color:#343147;
}

</style>