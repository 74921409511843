// utils.js

const imgpath = 'https://oss.zhizhutegong.com/';

export default {
	sSyn(k, v) {
		return localStorage.setItem(k, v)
	},
	gSyn(v) {
		return localStorage.getItem(v)
	},
	rSyn(v) {
		return localStorage.removeItem(v)
		// localStorage.clear();  清空所有
	},
	
	imgUrl(url) {
		return imgpath + url;
	},
	//时间戳转时间
	timeT (t, z) {
		if (z) {
			t = t * 1000
		} else {
			t = t * 1000
	
		}
		let date = new Date(t);
		let year = date.getFullYear(); //年
		let month = date.getMonth() + 1; //月
		month = month < 10 ? '0' + month : month
		let day = date.getDate(); //天
		day = day < 10 ? '0' + day : day
		let hours = date.getHours(); //小时
		hours = hours < 10 ? '0' + hours : hours;
		let Minutes = date.getMinutes(); //分
		Minutes = Minutes < 10 ? '0' + Minutes : Minutes;
		let Seconds = date.getSeconds(); //秒
		Seconds = Seconds < 10 ? '0' + Seconds : Seconds;
		if (z==1) {
			return !t ? '' : year + '年' + month + '月' + day + '日'
		} else if(z==2){
			return !t ? '' : year + '-' + month + '-' + day 
		}else{
			return !t ? '' : year + '-' + month + '-' + day + ' ' + hours + ':' + Minutes + ':' + Seconds
		}
	}
};